.checkbox {
    :global {
        .ant-row {
           flex-flow: row nowrap !important;
         }

          .ant-form-item-label { 
            overflow: visible;
         } 

         @media (max-width: 575px) {
            .ant-form-item-label {
                flex: 0 0 95% !important;
             }
            
           
          }
         
    }
  }