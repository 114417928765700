.container {
  padding: 20px;
  width: 100%;

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    .ant-spin-dot {
      font-size: 40px;
    }
  }

  .formContainer {
    margin: 0 auto;
    border: 1px solid black;
    padding: 20px;
    border-radius: 8px;
    max-width: 750px;
    position: relative;
    height: 100%;

    .btnSend {
      width: 100%;
    }

    .ant-form-item-row {
      justify-content: space-between;
      align-items: center;

      .ant-form-item-control {
        max-width: 320px;
      }
    }

    .disabledFormMessage {
      color: red;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 750px) {
  .container {
    .formContainer {
      max-width: 550px;

      .ant-form-item-row {
        .ant-form-item-control {
          max-width: 100%;
          flex: 0 0 100%;
        }

        .ant-form-item-label {
          margin-bottom: 15px;
        }
      }
    }
  }
}
